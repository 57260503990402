/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import {
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';

const PatientNoteModal = (style, currentUrl) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return <>
    <Link
      mb="40px"
      display="block"
      fontWeight={500}
      fontFamily="FedraSansStd-medium"
      fontSize={'18px'}
      bgColor="#cf4520"
      h={'auto'}
      onClick={onOpen}
      // textAlign="center"
      padding="10px 36px"
      borderRadius="5px"
      color="faf7f0"
      _hover={{
        textDecoration: 'none',
        bg: '#000054',
      }}
    >
      {style?.buttonText}
    </Link>
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent backgroundColor="#ffffff">
        <ModalHeader
          backgroundColor="#faf7f0"
          borderBottom="1px solid rgb(0 0 0 / 0%)"
          paddingBottom="45px"
          fontFamily="FedraSansStd-A-medium"
          clipPath="polygon(0 0, 100% 0, 100% 100%, 0 75%)"
        >
          <Box as="h4">IMPORTANT NOTE!</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          fontFamily="FedraSansStd-medium"
          fontSize="18px"
          display="flex"
          paddingTop="70px"
        >
          <Box width={{ xl: '20%', md: '20%', lg: '20%', sm: '2%' }}>
            &nbsp;
          </Box>
          <Box width="auto">
            Each patient must sign in with their own email ID. Each ID is
            linked to respective patients and contains all their treatment
            details.
            <Box as="p">&nbsp;</Box>
            In case a patient does not have an email ID, then please create a
            new email ID and sign in.
            <Box as="p">&nbsp;</Box>
            <Box marginTop="20px" textAlign="center">
              <NextLink href={currentUrl} passHref onClick={onClose} legacyBehavior>
                <Link
                  display="inline-block"
                  mb="20px"
                  fontFamily="FedraSansStd-medium"
                  borderRadius="5px"
                  bgColor="#cf4520"
                  color="rgb(250 247 240)"
                  fontSize="18px"
                  padding="14px 29px"
                  h="auto"
                  minH="50px"
                  _hover={{
                    textDecoration: 'none',
                  }}
                  target="_blank"
                >
                  OK
                </Link>
              </NextLink>
            </Box>
          </Box>
          <Box width={{ xl: '20%', sm: '2%' }}>&nbsp;</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>;
};

export default PatientNoteModal;
