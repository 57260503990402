/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useReducer, useState } from 'react';

/* External Imports */
import { Box, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import config from '@config';
import DesktopMenu from '@msr/components/Header/Menu/DesktopMenu';
import MobileMenu from '@msr/components/Header/Menu/MobileMenu';
import { AuthContext } from '@store/auth-context';

/* Services */

const HeaderReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_COUNTRY':
      return {
        ...state,
        openCountry: !state.openCountry,
        isActive: !state.isActive,
        openProfile: false,
        openMobileMenu: false,
      };
    case 'OPEN_PROFILE':
      return {
        ...state,
        openProfile: !state.openProfile,
        openCountry: false,
      };
    case 'OPEN_DESKTOP_SEARCH':
      return { ...state, openDeskSearch: !state.openDeskSearch };
    case 'OPEN_MOBILE_MENU':
      return {
        ...state,
        openMobileMenu: !state.openMobileMenu,
        openCountry: false,
      };
    case 'OPEN_MOBILE_SEARCH':
      return {
        ...state,
        openMobileSearch: !state.openMobileSearch,
        openCountry: false,
      };
    case 'CLOSE_DROPDOWN':
      return {
        ...state,
        openCountry: false,
        openProfile: false,
        openMobileProfile: false,
        openMobileSearch: false,
        openMobileMenu: false,
      };
    case 'OPEN_MOBILE_PROFILE':
      return { ...state, openMobileProfile: !state.openMobileProfile };
  }

  return {
    ...state,
    openCountry: false,
    openProfile: false,
    openMobileMenu: false,
    openMobileProfile: false,
    openMobileSearch: false,
    openDeskSearch: false,
  };
};

const Header = ({
  headerMenuData,
  region,
  language,
  // setMobileMenuOpen,
  // mobileMenuOpen,
  // setRegionDropdownOpen,
  // regionDropdownOpen,
  availableLocals = [],
  currentUserRegion,
  // setCurrentUserRegion,
  isStaticPage,
  staticPageURL,
  languageAlsoin,
  pageUrl,
}) => {
  // const currRegion = useContext(RegionContext);
  // const secRegion =
  //   headerMenuData?.allIsoMainMenus[4]?.submenuItems[0]?.key?.toLowerCase();
  // const renderContent = canRenderContent(currRegion, secRegion, headerMenuData);
  const [isActive, setActive] = useState(false);

  const [isLoading, dispatchLoading] = useReducer(HeaderReducer, {
    openCountry: false,
    openProfile: false,
    openMobileMenu: false,
    openMobileProfile: false,
    openMobileSearch: false,
    openDeskSearch: false,
  });
  const authCtx = useContext(AuthContext);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  region = authCtx.region || region;
  language = authCtx.lang || language;
  let counter = 0;
  // language = 'en';

  const router = useRouter();
  // useEffect(() => {
  //   setIsLoggedIn(authCtx.isLoggedIn);
  // }, [authCtx.isLoggedIn]);

  const languageDropdownHandler = () => {
    if (isLoading.openCountry == false) {
      dispatchLoading({ type: 'OPEN_COUNTRY' });
    }
  };

  const handleMouseOver = () => {
    countryDropdownHandler();
  };

  const handleMouseOut = () => {
    countryDropdownHandler();
  };
  //Handle country dropdown active styles
  useEffect(() => {
    let clickOutsideCountry = document.getElementById('langDropdown');
    document.addEventListener('click', function (event) {
      let isClickInsideElement = clickOutsideCountry?.contains(event.target);
      if (!isClickInsideElement) {
        setActive(false);
      }
    });
  }, [isActive]);

  useEffect(() => {
    dispatchLoading({
      openCountry: false,
      openProfile: false,
      openMobileMenu: false,
      openMobileProfile: false,
      openMobileSearch: false,
      openDeskSearch: false,
    });
  }, [router.asPath]);

  const countryDropdownHandler = () => {
    // setActive(!isActive);
    // dispatchLoading({ type: 'OPEN_COUNTRY' });
    if (isLoading.openCountry == false) {
      dispatchLoading({ type: 'OPEN_COUNTRY' });
      setActive(isActive);
    } else {
      dispatchLoading({ type: 'OPEN_COUNTRY' });
      setActive(!isActive);
    }
  };

  const languagelisting = () => {
    let currentURL = `${pageUrl?.split('/')[2] != ''
      ? pageUrl?.split('/')[2]
      : pageUrl?.split('/')[1]
      }`;
    let uniqueRegions = [];
    config.msrlanguage?.map(locale => {
      uniqueRegions?.push(locale);
    });
    let displayLanguage = '';
    uniqueRegions.forEach(item => {
      if (item.language_sf === language) {
        displayLanguage = item.language;
      }
      return;
    });
    if (displayLanguage === '') {
      if (currentURL !== 'mahashivratri' || currentURL === 'mahashivratri') {
        displayLanguage = 'English';
      }
    }
    return (
      <>
        {displayLanguage != '' && (
          <>
            <Box
              borderLeft={{
                base: 'none',
                sm: 'none',
                lg: '1px solid #ffffff66',
              }}
              pos="relative"
              zIndex="1"
              userSelect="none"
              pl={{ base: '0', sm: '0', lg: '7px' }}
            >
              <Box
                float="left"
                w="auto"
                // onClick={() => countryDropdownHandler()}
                id="langDropdown"
                onMouseOver={isDesktop ? handleMouseOver : null}
                onMouseOut={isDesktop ? handleMouseOut : null}
              >
                <Box
                  textDecoration="none"
                  p={{ base: '6px 1px 5px 10px', md: '5px 1px 5px 3px' }}
                  bgSize="10px"
                  bgRepeat="no-repeat"
                  bgPos="90% 45%"
                  cursor="pointer"
                  float="left"
                  fontFamily="FedraSansStd-book"
                  fontSize="14px"
                  color="#FFF7E9"
                  ml={{ base: '0', md: '0', lg: '6px' }}
                  className="languageDropdownWrapper"
                  minH="32px"
                >
                  {displayLanguage}
                  <Box display="inline-flex" width="13px" paddingLeft="5px">
                    {/* <LazyLoadImageComponent
                      src={`${config.imgPath}/d/46272/1665478265-icon_downarrow.svg`}
                      w="8px"
                      alt="down arrow"
                      h="6px"
                    /> */}
                    <svg
                      width="8"
                      height="6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      transform="scale(1.4,1.4)"
                    >
                      <path
                        d="M3.634 3.677a.8.8 0 0 1-1.268 0L.527 1.288A.8.8 0 0 1 1.161 0H4.84a.8.8 0 0 1 .634 1.288l-1.84 2.389Z"
                        fill="#ECB11B"
                      />
                    </svg>
                  </Box>
                </Box>
              </Box>
              {languageAlsoin?.length > 0 && (
                <Box
                  pos="absolute"
                  w="90px"
                  left={{ base: '0', lg: '-11px' }}
                  top="38px"
                  opacity={`${isLoading.openCountry ? '1' : ''}`}
                  display={`${isLoading.openCountry ? 'block' : 'none'}`}
                  className="languagelist"
                >
                  <Box
                    w="90px"
                    bgColor="#FFF7E9"
                    borderRadius="5px"
                    border="1px solid #f0f0f0"
                    boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
                    className="languageList"
                  >
                    <UnorderedList m="0" p="0" className="langlist">
                      {renderLanguageList()}
                    </UnorderedList>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
      </>
    );
  };

  const renderLanguageList = () => {
    let uniqueRegions = [];
    languageAlsoin?.map(locale => {
      uniqueRegions.push(locale);
    });
    let url;
    return uniqueRegions?.map((locale, i) => {
      url = locale?.url;
      return ListLanguages(url, locale, i);
    });
  };

  const ListLanguages = (url, locale) => {
    return (
      <>
        <ListItem
          display="block"
          listStyleType="none"
          onClick={() => countryDropdownHandler()}
          paddingBottom="0"
        >
          <NextLink
            href={url}
            passHref
            prefetch={false}
            scroll={false}
            _hover={{ borderBottom: 'none' }}
            legacyBehavior
          >
            <Link
              display="block"
              fontSize={{ base: '16px', lg: '14px' }}
              color="#cf4520"
              textDecoration="none"
              p={{ base: '8px 10px' }}
              fontWeight="300"
              fontFamily="'FedraSansStd-book', sans-serif"
              _hover={{ bgColor: 'none' }}
              onClick={() => dispatchLoading({ type: 'OPEN_MOBILE_MENU' })}
              cursor="pointer"
            >
              {locale.title}
            </Link>
          </NextLink>
        </ListItem>
      </>
    );
  };

  const handleClickOutside = () => {
    dispatchLoading({ type: 'CLOSE_DROPDOWN' });
  };

  const mobileMenuHandler = () => {
    dispatchLoading({ type: 'OPEN_MOBILE_MENU' });
  };

  const mobileSearchHandler = () => {
    dispatchLoading({ type: 'OPEN_MOBILE_SEARCH' });
  };

  const deskSearchHandler = () => {
    dispatchLoading({ type: 'OPEN_DESKTOP_SEARCH' });
  };
  const renderRegionsList = () => {
    let uniqueRegionsNames = [],
      uniqueRegions = [];
    config.locales.map(locale => {
      if (uniqueRegionsNames.indexOf(locale.region) === -1) {
        uniqueRegionsNames.push(locale.region);
        uniqueRegions.push(locale);
      }
    });
    let url;
    return uniqueRegions.map(locale => {
      if (isStaticPage) {
        url =
          isStaticPage &&
          `/${locale.region_sf}/${locale.language_sf}/${staticPageURL}`;
        return (
          <ListItem display="block" key={locale.region} listStyleType="none">
            <Link
              display="block"
              fontSize={{ base: '16px', lg: '14px' }}
              lineHeight={{ base: '20px', lg: '3.14' }}
              color="#cf4520"
              textDecoration="none"
              p={{ base: '20px 0 20px 30px', lg: '5px 15px' }}
              fontWeight="300"
              fontFamily='"FedraSansStd-book", sans-serif'
              href={url}
              _hover={{ bgColor: '#e4ded4' }}
            >
              {locale.region}
            </Link>
          </ListItem>
        );
      } else if (availableLocals) {
        return availableLocals.map((available_local, i) => {
          const available_local_values =
            available_local &&
            available_local.value &&
            available_local.value.split('/');
          const available_local_region = available_local_values[1];
          const available_local_language = available_local_values[2];
          if (
            available_local_region == locale.region_sf &&
            available_local_language == locale.language_sf
          ) {
            url = available_local.value;
            return ListLanguages(url, locale, i);
          }
        });
      } else {
        if (counter === 0) {
          counter = counter + 1;
          const locales = getLocales();
          return locales.map((el, i) => {
            const currentURL = router.pathname;
            const part = currentURL?.split('/')[3]; // returns url after language
            const url = `/${el.region_sf}/${el.language_sf}/${part}`;
            return ListLanguages(url, el, i);
          });
        }
      }
    });
  };

  const getLocales = () => {
    let regions = [];
    return config.locales.filter((el, index) => {
      if (
        index === 0 ||
        (regions?.length && regions.indexOf(el.region) === -1)
      ) {
        regions.push(el.region);
        return el;
      }
    });
  };

  const loginURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}`;
  return (
    <Box
      as="header"
      className="msr_header"
      zIndex="33"
      pos="relative"
      onBlur={() => {
        handleClickOutside();
      }}
    >
      <DesktopMenu
        headerMenuData={headerMenuData}
        renderLanguageList={renderLanguageList}
        languagelisting={languagelisting}
        region={region}
        language={language}
        isStaticPage={isStaticPage}
        deskSearchHandler={deskSearchHandler}
        isOpenDeskSearch={isLoading.openDeskSearch}
      >
        {' '}
      </DesktopMenu>
      <MobileMenu
        headerMenuData={headerMenuData}
        renderLanguageList={renderLanguageList}
        languagelisting={languagelisting}
        languageDropdownHandler={languageDropdownHandler}
        currentUserRegion={currentUserRegion}
        renderRegionsList={renderRegionsList}
        isOpenCountry={isLoading.openCountry}
        mobileMenuHandler={mobileMenuHandler}
        isOpenMobMenu={isLoading.openMobileMenu}
        mobileSearchHandler={mobileSearchHandler}
        isOpenMobSearch={isLoading.openMobileSearch}
        urlLogin={loginURL}
        // countryDropdownRegionHandler={countryDropdownRegionHandler}
        region={region}
        language={language}
        isStaticPage={isStaticPage}
      />
      <style global jsx>
        {`
          .menu_L1 #countryDropdown li:hover,
          .menu_L1 #countryDropdown li:hover a {
            color: #ffffff;
            text-decoration: none;
            border-bottom: none;
            background: transparent;
          }
          .menu_L1 #countryDropdown li:last-child {
            margin-left: 0;
          }
          .menu_L1 .langlist li {
            padding: 0px;
            border-bottom: none;
            margin-left: 12px;
          }
          .menu_L1 .languageList a {
            padding-left: 4px;
            padding-right: 4px;
            padding-bottom: 0px;
            display: block;
          }
          .menu_L1 .languageList li {
            width: auto;
            display: grid;
          }
          .menu_L1 .languageList li:last-child {
            margin-bottom: 10px;
          }
          .menu_L1 .langlist li:hover {
            border-bottom: none;
          }
          #countryDropdown li {
            border-bottom: none;
            padding-bottom: 5px !important;
          }
          #countryDropdown li:last-child {
            margin-left: 0px;
          }
          .languageList::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: 24px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #fff7e9 transparent;
          }
          input[type='search']::-webkit-search-decoration,
          input[type='search']::-webkit-search-cancel-button,
          input[type='search']::-webkit-search-results-button,
          input[type='search']::-webkit-search-results-decoration {
            display: none;
          }
        `}
      </style>
    </Box>
  );
};

export default Header;
